import { toCamelCase } from 'eficia/utilities/toCamelCase';
import { ELECTRICITY_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'alerts';
const baseUrl = ELECTRICITY_API;

export default function alertsApi(isConnected = false) {
  return {
    async fetchAlertsHistory(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchAlertsHistory',
        params
      });
    },
    async fetchAlertHistoryDetails(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchAlertHistoryDetails',
        params
      });
    },
    async fetchOrganization(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchOrganization',
        params
      });
    },
    async fetch(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetch',
        params
      });
    },
    async fetchOne(params, type) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: toCamelCase(`fetchOne-${type}`),
        params
      });
    },
    async create(params, type) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: toCamelCase(`create-${type}`),
        params
      });
    },
    async edit(params, type) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: toCamelCase(`edit-${type}`),
        params
      });
    },
    async delete(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'delete',
        params
      });
    },
    async subscribe(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'subscribe',
        params
      });
    },
    async unsubscribe(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'unsubscribe',
        params
      });
    },
    async massDelete(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'massDelete',
        params
      });
    },
    async exportHistory(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'exportHistory',
        params
      });
    },
    async exportHistoryDetails(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'exportHistoryDetails',
        params
      });
    },
    async countByGroups(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: ELECTRICITY_API,
        endpoint: 'countByGroups',
        params
      });
    }
  };
}
