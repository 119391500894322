import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { List, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { AccessContext } from 'eficia/contexts/AccessProvider';
import { UserContext } from 'eficia/contexts/UserProvider';

import SidebarMenuListItem from './SidebarMenuListItem';

// Copie récursivement les onglets afin de manipuler une copie sans impacter la référence
function copyTabs(pages) {
  return pages.map((page) => {
    if (page.content) {
      return { ...page, content: copyTabs([...page.content]) };
    }
    return { ...page };
  });
}

// Enlève récursivement les onglets qui ne sont pas accessibles
function removeTabs(pages, accessProps) {
  return pages.filter((page) => {
    if (page.content) {
      page.content = removeTabs(page.content, accessProps);
      return page.content.length > 0;
    }
    return !page.hasAccess || page.hasAccess(accessProps);
  });
}

// Ouvre l'onglet courant
function openCurrentTab(location, pages) {
  pages.forEach((page) => {
    if (page.content) {
      openCurrentTab(location, page.content);
    }
    if (
      page.to &&
      (matchPath(page.to, location.pathname) || location.pathname.startsWith(page.to))
    ) {
      page.isOpen = true;
    }
  });
}

// Ouvre récursivement les onglets parents de l'onglet courant
function openParentsTabs(pages) {
  let isChild = false;
  pages.forEach((page) => {
    if (page.isOpen) {
      isChild = true;
    }
    if (page.content) {
      if (openParentsTabs(page.content)) {
        page.isOpen = true;
        isChild = true;
      }
    }
  });
  return isChild;
}

// Remonte le lien de l'enfant au parent si aucun lien n'est spécifié au niveau du parent
function followUpLinks(pages) {
  pages.forEach((page) => {
    if (page.content) {
      if (!page.to && page.content[0]?.to) {
        page.to = page.content[0].to;
      }
      return followUpLinks(page.content);
    }
  });
}

function reduceChildRoutes(props) {
  const { items, page, depth, setSidebarToggleMobile } = props;

  if (page.content) {
    items.push(
      <SidebarMenuListItem
        depth={depth}
        href={page.to}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        isOpen={page.isOpen}
        title={page.label}
        slug={page.slug}
        hasContent
        setSidebarToggleMobile={setSidebarToggleMobile}
      >
        <SidebarMenuList
          depth={depth + 1}
          pages={page.content}
          setSidebarToggleMobile={setSidebarToggleMobile}
        />
      </SidebarMenuListItem>
    );
    return items;
  }

  items.push(
    <SidebarMenuListItem
      depth={depth}
      href={page.to}
      icon={page.icon}
      key={page.label}
      label={page.badge}
      title={page.label}
      filterTab={page.filterTab}
      slug={page.slug}
      eficiaPro={page.eficiaPro}
      setSidebarToggleMobile={setSidebarToggleMobile}
    />
  );
  return items;
}

function SidebarMenuList(props) {
  const { depth, pages, setSidebarToggleMobile } = props;

  return (
    <List className={`depth-${depth}`}>
      {pages.reduce((items, page) => reduceChildRoutes({ ...props, items, page }), [])}
    </List>
  );
}

SidebarMenuList.propTypes = {
  depth: PropTypes.number.isRequired,
  pages: PropTypes.array.isRequired,
  setSidebarToggleMobile: PropTypes.func.isRequired
};

function SidebarMenu(props) {
  const { title, pages, component: Component, setSidebarToggleMobile, ...rest } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const { groupNotFound, userData, userSidebarPermissions } = useContext(UserContext);
  const { allowedAccesses } = useContext(AccessContext);

  const [filteredPages, setFilteredPages] = useState([]);

  useEffect(() => {
    let copiedPages = copyTabs(pages);
    copiedPages = removeTabs(copiedPages, {
      groupNotFound,
      userData,
      userSidebarPermissions,
      allowedAccesses,
      isProduction: process.env.REACT_APP_EFICIA_ENVIRONMENT === 'production'
    });
    openCurrentTab(location, copiedPages);
    openParentsTabs(copiedPages);
    followUpLinks(copiedPages);
    setFilteredPages(copiedPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, allowedAccesses, userSidebarPermissions]);

  if (!filteredPages.length) {
    return null;
  }

  return (
    <Component {...rest}>
      {title && (
        <Typography variant="subtitle2" style={{ height: 44 }}>
          {t(title)}
        </Typography>
      )}
      <div style={{ padding: '0 16px 0 16px' }}>
        <SidebarMenuList
          depth={0}
          pages={filteredPages}
          setSidebarToggleMobile={setSidebarToggleMobile}
        />
      </div>
    </Component>
  );
}

SidebarMenu.propTypes = {
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  setSidebarToggleMobile: PropTypes.func.isRequired
};

export default SidebarMenu;
