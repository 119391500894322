import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../checkbox';

function NodeLabel(props) {
  const handleCheckboxChange = (e) => {
    const { id, onCheckboxChange, checked, mode } = props;

    if (mode === 'simpleSelect') {
      onCheckboxChange(id, true);
    } else {
      onCheckboxChange(id, !checked, undefined, e);
    }

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const {
    value,
    disabled,
    readOnly,
    mode,
    title,
    countLabel,
    icon,
    label,
    id,
    partial,
    checked,
    dataTestId
  } = props;
  const { t } = useTranslation();

  const nodeLabelProps = { className: 'node-label' };

  // in case of simple select mode, there is no checkbox, so we need to handle the click via the node label
  // but not if the control is in readOnly or disabled state
  const shouldRegisterClickHandler = mode === 'simpleSelect' && !readOnly && !disabled;

  if (shouldRegisterClickHandler) {
    nodeLabelProps.onClick = handleCheckboxChange;
  }

  const sharedProps = {
    id,
    value,
    checked,
    disabled,
    readOnly,
    mode,
    tabIndex: -1
  };
  const className = ['checkbox-item', mode === 'simpleSelect' && 'simple-select']
    .filter(Boolean)
    .join(' ');

  return (
    <label
      title={t(title || label)}
      htmlFor={id}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <span {...nodeLabelProps}>
        {/* eslint-disable-next-line react/no-danger */}
        {icon && <span dangerouslySetInnerHTML={{ __html: icon }} />} {t(label)}
        {countLabel ? (
          <span style={{ color: 'var(--dark)', fontSize: 14, marginLeft: 4 }}>{countLabel}</span>
        ) : null}
      </span>
      <Checkbox
        name={id}
        className={className}
        indeterminate={partial}
        onChange={handleCheckboxChange}
        data-testid={dataTestId ? `${dataTestId}-label` : undefined}
        {...sharedProps}
      />
    </label>
  );
}

NodeLabel.propTypes = {
  id: PropTypes.string.isRequired,
  actions: PropTypes.array,
  title: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  partial: PropTypes.bool,
  disabled: PropTypes.bool,
  dataset: PropTypes.object,
  mode: PropTypes.oneOf(['multiSelect', 'simpleSelect']),
  onCheckboxChange: PropTypes.func,
  readOnly: PropTypes.bool,
  clientId: PropTypes.string,
  dataTestId: PropTypes.string,
  countLabel: PropTypes.string
};

export default NodeLabel;
