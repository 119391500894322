import { useCallback } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import countryISO2 from 'country-iso-3-to-2';

import anonymousClient from 'eficia/services/anonymousClient';

// Récupérer les données de l'utilisateur connecté
export function useFetchConnectedUserData() {
  const client = anonymousClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetcher = useCallback(async () => {
    const response = await client.user.me();

    const tagManagerArgs = {
      dataLayer: {
        event: 'user_information',
        userId: response.data?.data?.id,
        role: response.data?.data?.role,
        isEficiaEmployee: !!response.data?.data?.email?.endsWith('@eficia.com'),
        organizationId: response.data?.data?.organizationId,
        organizationName: response.data?.data?.organizationName
      }
    };
    TagManager.dataLayer(tagManagerArgs);

    if (response.data?.data?.isUnknown === true) {
      navigate('/is-unknown');
    } else if (response.data?.data?.organizationId === null) {
      navigate('/organization-not-found');
    }

    return response;
  }, [client.user, navigate]);

  const { data, error, isFetching } = useQuery(['user-data'], fetcher);

  let formatData = null;
  if (data?.data?.data) {
    formatData = data?.data?.data;

    if (formatData.countries?.length) {
      formatData.countries = formatData.countries
        .map((country) => ({
          ...country,
          localizedName: t(`country.${country.id.toLowerCase()}.name`)
        }))
        .sort((a, b) => (a.localizedName > b.localizedName ? 1 : -1))
        .map((country) => ({
          ...country,
          alpha2: countryISO2(country.id) || 'XX',
          langEnergyCenter: country.id === 'ESP' ? 'es' : 'fr' // TODO: remove this when we have the lang in the API
        }));
    }
  }

  return {
    data: formatData,
    error,
    isFetching
  };
}
