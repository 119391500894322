import { getEficiaApiUrl } from './helpers';

// https://example.com/api
export const EFICIA_BASE_API = getEficiaApiUrl();

// auth / user
export const USER_API = `${EFICIA_BASE_API}/user`;

// sites
export const SITES_API = `${EFICIA_BASE_API}/site`;
export const GRDF_API = `${EFICIA_BASE_API}/grdf`;
export const ENEDIS_API = `${EFICIA_BASE_API}/enedis`;
export const DATADIS_API = `${EFICIA_BASE_API}/datadis`;

// translation (temp url)
export const TRANSLATION_API = `${EFICIA_BASE_API}/translation`;

// harmony
export const HARMONY_API = `${EFICIA_BASE_API}/harmony`;

// electricity
export const ELECTRICITY_API = `${EFICIA_BASE_API}/electricity`;

// dataviz
export const DATA_VISUALIZATION_API = `${EFICIA_BASE_API}/data-visualization`;

// chatbot
export const CHATBOT_BASE_API = process.env.REACT_APP_CHATBOT_API_URL;
