import FormData from 'form-data';
import moment from 'moment';

import axiosConfig from './config/axiosConfig';
import { EFICIA_BASE_API, GRDF_API, SITES_API, ELECTRICITY_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'sites';
const baseUrl = SITES_API;

export default function sitesApi(isConnected = false) {
  return {
    // sites
    async fetchFullByGroupId(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchFullByGroupId',
        params
      });
    },

    async fetchFull() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchFull'
      });
    },

    async deleteMyOrganization() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'deleteMyOrganization'
      });
    },

    async fetch(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetch',
        params
      });
    },
    async massDelete(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'massDelete',
        params
      });
    },
    async massCreate(params) {
      const fd = new FormData();
      fd.append('file', params.file);

      if (params.measureSource) {
        fd.append('measureSource', params.measureSource);
      }

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const query = await axiosConfig.post(`${SITES_API}/upload-site-list-file`, fd, config);

      return query;
    },
    async massSitesEdit(params) {
      const fd = new FormData();
      fd.append('file', params.file);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const query = await axiosConfig.post(
        `${SITES_API}/upload-site-to-update-list-file`,
        fd,
        config
      );

      return query;
    },
    async massElectricityEdit(params) {
      const fd = new FormData();
      fd.append('file', params.file);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const query = await axiosConfig.post(
        `${SITES_API}/add-electricity-meters-to-site-from-uploaded-file`,
        fd,
        config
      );

      return query;
    },
    async massHoursEdit(params) {
      const fd = new FormData();
      fd.append('file', params.file);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const query = await axiosConfig.post(
        `${SITES_API}/upload-site-opening-hours-to-update-list-file`,
        fd,
        config
      );

      return query;
    },

    async downloadOpeningHoursSitesFile() {
      const query = await axiosConfig.post(
        `${SITES_API}/export-site-opening-hours-to-update-list-file`,
        {},
        {
          responseType: 'blob'
        }
      );

      return query;
    },
    async downloadSitesFile() {
      const query = await axiosConfig.post(
        `${SITES_API}/export-site-to-update-list-file`,
        {},
        {
          responseType: 'blob'
        }
      );

      return query;
    },
    async downloadElectricityFile() {
      const query = await axiosConfig.post(
        `${SITES_API}/export-electricity-meters-to-update-file`,
        {},
        {
          responseType: 'blob'
        }
      );

      return query;
    },
    async create(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'create',
        params
      });
    },
    async edit(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'edit',
        params
      });
    },
    async editOpeningHours(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'editOpeningHours',
        params
      });
    },
    async fetchOne(siteId) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchOne',
        params: { siteId }
      });
    },
    async count(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'count',
        params
      });
    },
    async fetchSitesRatio(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchSitesRatio',
        params
      });
    },
    async fetchGasCarbonImpact(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: EFICIA_BASE_API,
        endpoint: 'fetchGasCarbonImpact',
        params
      });

      const formatData = [];
      let withFormatedData = false;
      if (query.data?.data.length === 2) {
        if (query.data.data[0].consumption !== 0 || query.data.data[1].consumption !== 0) {
          formatData.push(query.data.data[1]);
          formatData.push(query.data.data[0]);
          withFormatedData = true;
        }
      }
      return {
        data: {
          data: withFormatedData ? formatData : []
        }
      };
    },
    async fetchCarbonImpact(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: EFICIA_BASE_API,
        endpoint: 'fetchCarbonImpact',
        params
      });
    },
    async fetchSiteCarbonImpact(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: EFICIA_BASE_API,
        endpoint: 'fetchSiteCarbonImpact',
        params
      });

      const formatData = [];
      let withFormatedData = false;
      if (query.data?.data.length === 1) {
        formatData.push({
          date: moment(query.data.data[0].date, 'YYYY-MM').subtract(1, 'y').format('YYYY-MM'),
          consumption: null,
          carbonEmission: null,
          treeCount: null,
          consumptionRatio: null
        });

        formatData.push(query.data.data[0]);
        withFormatedData = true;
      }

      if (query.data?.data.length === 2) {
        if (query.data.data[0].consumption !== 0 || query.data.data[1].consumption !== 0) {
          formatData.push(query.data.data[1]);
          formatData.push(query.data.data[0]);
          withFormatedData = true;
        }
      }

      return {
        data: {
          data: withFormatedData ? formatData : []
        }
      };
    },
    async fetchSiteGasCarbonImpact(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: GRDF_API,
        endpoint: 'fetchSiteGasCarbonImpact',
        params
      });

      const formatData = [];
      let withFormatedData = false;
      if (query.data?.data.length === 1) {
        formatData.push({
          date: moment(query.data.data[0].date, 'YYYY-MM').subtract(1, 'y').format('YYYY-MM'),
          consumption: null,
          carbonEmission: null,
          treeCount: null,
          consumptionRatio: null
        });

        formatData.push(query.data.data[0]);
        withFormatedData = true;
      }

      if (query.data?.data.length === 2) {
        if (query.data.data[0].consumption !== 0 || query.data.data[1].consumption !== 0) {
          formatData.push(query.data.data[1]);
          formatData.push(query.data.data[0]);
          withFormatedData = true;
        }
      }

      return {
        data: {
          data: withFormatedData ? formatData : []
        }
      };
    },

    async fetchConsumptionPerSquareMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchConsumptionPerSquareMeter',
        params
      });
    },
    async fetchGasConsumptionPerSquareMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchGasConsumptionPerSquareMeter',
        params
      });
    },
    // zones
    async createZone(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'createZone',
        params
      });
    },
    async deleteZone(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'deleteZone',
        params
      });
    },
    async fetchZonePerMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchZonePerMeter',
        params
      });
    },

    // electricity meters
    async fetchOrganizationMeters(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchOrganizationMeters',
        params
      });
    },
    async fetchMeters(siteId) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchMeters',
        params: { siteId }
      });
    },

    async countGasMeters(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'countGasMeters',
        params
      });
    },

    async countMeters(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'countMeters',
        params
      });
    },
    async createMeter(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'createMeter',
        params
      });

      if (query?.data?.data) {
        query.data.data.siteId = params.siteId;
      }

      return query;
    },
    async askEmailConsent(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: ELECTRICITY_API,
        endpoint: 'askEmailConsent',
        params
      });

      return query;
    },

    async editMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'editMeter',
        params
      });
    },
    async deleteMeter(siteId, meterId) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'deleteMeter',
        params: {
          siteId,
          id: meterId
        }
      });
    },
    // gas meters
    async fetchGasMeters(siteId) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: GRDF_API,
        endpoint: 'fetchGasMeters',
        params: { siteId }
      });
    },
    async createGasMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: GRDF_API,
        endpoint: 'createGasMeter',
        params
      });
    },
    async editGasMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: GRDF_API,
        endpoint: 'editGasMeter',
        params
      });
    },
    async editGasMeterZones(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: GRDF_API,
        endpoint: 'editGasMeterZones',
        params
      });
    },
    async deleteGasMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: GRDF_API,
        endpoint: 'deleteGasMeter',
        params
      });
    },
    async fetchContractDetails(measureSourceSlug, params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: `${EFICIA_BASE_API}/${measureSourceSlug}`,
        endpoint: 'fetchContractDetails',
        params
      });
    },
    async fetchMeasureSources(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: SITES_API,
        endpoint: 'fetchMeasureSources',
        params
      });
    },
    async fetchSitesMetersInfos(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchSitesMetersInfos',
        params
      });
    },
    async downloadGroupsFile() {
      return axiosConfig.post(
        `${SITES_API}/export-site-groups-to-update-list-file`,
        {},
        {
          responseType: 'blob'
        }
      );
    },
    async massGroupsEdit(params) {
      const fd = new FormData();
      fd.append('file', params.file);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      return axiosConfig.post(`${SITES_API}/upload-site-groups-to-update-list-file`, fd, config);
    }
  };
}
