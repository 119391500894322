import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Hidden, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { UserContext } from 'eficia/contexts/UserProvider';
import { LayoutContext } from 'eficia/contexts/LayoutProvider';
import sidebarLogo from 'assets/eficia/logos/sidebar_logo.svg';
import { HOME_PATH } from 'eficia/constants/paths';
import Badge from 'eficia/components/atoms/Badge/Badge';

import { AppHeader, MenuButtonContainer } from './Header.style';

function Header({ sidebarToggleMobile, setSidebarToggleMobile }) {
  const { t } = useTranslation();

  const { isPageTitlePresent } = useContext(LayoutContext);
  const { userData } = useContext(UserContext);

  return (
    <AppHeader
      style={{
        backgroundColor: isPageTitlePresent ? 'white' : 'transparent'
      }}
      elevation={1}
      data-testid="header"
    >
      <Hidden lgUp>
        {/* mobile */}
        <MenuButtonContainer>
          <IconButton
            color="inherit"
            onClick={() => setSidebarToggleMobile(!sidebarToggleMobile)}
            size="large"
          >
            {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
          </IconButton>
        </MenuButtonContainer>
      </Hidden>
      <Hidden lgDown>
        {/* desktop */}
        <div className="d-flex justify-content-center align-items-baseline" style={{ gap: 8 }}>
          <div>
            <Link to={HOME_PATH} style={{ padding: '24px 0px 24px 22px' }}>
              <img src={sidebarLogo} height="32" alt="Eficia" />
            </Link>
          </div>
          {userData.organizationEficiaPro && (
            <Badge
              color="tertiary"
              label={t('sidebar.header.label.eficia_pro')}
              styles={{ textTransform: 'none' }}
            />
          )}
        </div>
      </Hidden>
    </AppHeader>
  );
}

Header.propTypes = {
  setSidebarToggleMobile: PropTypes.func,
  sidebarToggleMobile: PropTypes.bool
};

export default Header;
