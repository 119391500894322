import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mui/material';
import PropTypes from 'prop-types';

import { PROFILE_HOME_PATH } from 'eficia/constants/paths';
import { UserContext } from 'eficia/contexts/UserProvider';
import useFetchCountries from 'eficia/views/lists/services/useFetchCountries';

import useCreateOrganization from '../services/useCreateOrganization';
import useFetchOrganizations from '../services/useFetchOrganizations';
import useLogout from '../services/useLogout';
import OrganizationFormModal from './OrganizationFormModal';
import { ActionItem, ActionList } from './SidebarProfileMenu.style';
import SidebarProfileMenuOrganizations from './SidebarProfileMenuOrganizations';

export default function SidebarProfileMenu({ anchorEl, onClose, userData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  function toggleCreateModal() {
    setIsCreateModalOpen(!isCreateModalOpen);
  }
  const { data: organizations } = useFetchOrganizations();

  const { changeOrganization, organizationId } = useContext(UserContext);

  const { data: countries } = useFetchCountries();

  const logout = useLogout();
  const create = useCreateOrganization();

  const handleSubmit = (values) => {
    create(values, {
      onSuccess: () => {
        setIsCreateModalOpen(false);
      }
    });
  };

  const handleLogout = () => {
    logout();
  };

  const handleRedirectionToProfile = () => {
    onClose();
    navigate(PROFILE_HOME_PATH);
  };

  return (
    <>
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        MenuListProps={{
          variant: 'menu'
        }}
        onClose={onClose}
        style={{
          marginLeft: 0,
          marginTop: -66
        }}
      >
        <div
          className="dropdown-menu-right dropdown-menu-lg overflow-hidden"
          style={{ width: 256 }}
          data-testid="sidebar-profile-menu"
        >
          {organizations && (
            <SidebarProfileMenuOrganizations
              organizationId={organizationId}
              organizations={organizations}
              changeOrganization={changeOrganization}
            />
          )}

          <ActionList>
            {!userData.isSso && (
              <ActionItem
                onClick={toggleCreateModal}
                data-testid="sidebar-profile-menu-create-organization"
              >
                <span style={{ width: 16 }}>
                  <i className="fa-solid fa-plus" />
                </span>
                <span style={{ marginLeft: -6 }}>{t('organization.create_modal.title')}</span>
              </ActionItem>
            )}
            <ActionItem onClick={handleRedirectionToProfile}>
              <span style={{ width: 16 }}>
                <i className="fa-solid fa-user-gear" />
              </span>
              <span style={{ marginLeft: -4 }}>{t('profile.view.home.edit_my_profile')}</span>
            </ActionItem>
            <ActionItem onClick={handleLogout}>
              <span style={{ width: 16 }}>
                <i className="fa-solid fa-arrow-right-from-bracket" />
              </span>
              <span style={{ marginLeft: -5 }}>{t('profile.view.home.logout')}</span>
            </ActionItem>
          </ActionList>
        </div>
      </Menu>
      {isCreateModalOpen && (
        <OrganizationFormModal
          headerTitle={t('organization.create_modal.title')}
          isModalOpen={isCreateModalOpen}
          onSubmit={handleSubmit}
          submitLabel={t('button.validate')}
          onClose={toggleCreateModal}
          typeButton="submit"
          availableCountries={countries}
        />
      )}
    </>
  );
}

SidebarProfileMenu.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  userData: PropTypes.object
};
