import { useCallback, useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-query';

import { UserSiteContext } from 'eficia/contexts/UserSiteProvider';
import eficiaClient from 'eficia/services/eficiaClient';

import useDebounce from 'eficia/hooks/useDebounce';

// Attendre tant de miliseconds après la dernière touche appuyer avant de faire une requête
const DEBOUNCE_DELAY_MS = 250;

export default function useFetchSites({ siteName, page }) {
  const client = eficiaClient();
  const { siteData, isFetchingSite } = useContext(UserSiteContext);
  const { data: site } = siteData;

  const [isFirstFetching, setIsFirstFetching] = useState(true);

  const [debouncedData, setDebouncedData] = useState([]);

  // Prevenir l'écriture rapide au clavier
  const debouncedSiteName = useDebounce(siteName, DEBOUNCE_DELAY_MS);

  const fetcher = useCallback(() => {
    const params = {
      perPage: 100,
      sortBy: 'name',
      sortDirection: 'asc'
    };

    if (page) {
      params.page = parseInt(page);
    } else {
      params.page = 1;
    }

    if (debouncedSiteName) {
      params.filters = {
        name: debouncedSiteName
      };
    }

    return client.sites.fetch(params);
  }, [client.sites, debouncedSiteName, page]);

  const { data, error, isFetching, refetch } = useQuery(
    ['paginated-sites-list', debouncedSiteName, page],
    fetcher
  );

  useEffect(() => {
    if (isFirstFetching && !isFetching) {
      setIsFirstFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    if (data?.data?.data) {
      if (data.data.data.length) {
        const newDatas = [
          ...data.data.data.map((data) => {
            return {
              label: data.name,
              value: data.id,
              checked: data.id === site?.id
            };
          })
        ];
        if (!debouncedSiteName && page > 1) {
          newDatas.unshift(
            ...debouncedData.filter((d) => !newDatas.find((n) => n.value === d.value)) // pour eviter les doublons
          );
        }

        if (site && !newDatas.find((s) => s.value === site.id)) {
          newDatas.unshift({
            checked: true,
            label: site.name,
            value: site.id
          });
        }

        setDebouncedData(newDatas);
      } else if (debouncedSiteName) {
        setDebouncedData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, data, debouncedSiteName]);

  return {
    data: debouncedData,
    meta: data?.data?.meta,
    error,
    isFetching,
    isFirstFetching,
    isFetchingSite,
    refetch
  };
}
