import React from 'react';
import PropTypes from 'prop-types';

import { ErrorBlock } from 'eficia/components/molecules/ErrorBlock';

export default function ErrorWidget({ backgroundColor = 'var(--bg-contrast)', style = {} }) {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        width: '100%',
        height: '100%',
        backgroundColor,
        ...style
      }}
      data-testid="error-widget"
    >
      <ErrorBlock />
    </div>
  );
}

ErrorWidget.propTypes = {
  backgroundColor: PropTypes.string,
  style: PropTypes.object
};
